// Dependencies
import { makeAsyncAPIRequest, tokenHeader } from '../helpers/api';
import { apiVersion } from '../config';
const resource = 'users';
const usersService = {
	/*
    Gets the session from the API via the browser cookie
  */
	getAll: async () => {
		const method = 'get';
		const url = `/${apiVersion}/admin/${resource}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({ method, url, headers });
		return res.data;
	},
};

export default usersService;
