import { getItem, setItem, removeItem } from '../helpers/storage';
const path = '/';
const maxAge = 1 * 60 * 60 * 24 * 30; // Sessions expire after 30 days

const tokenService = {
	get: () => getItem('token'),
	set: value => setItem('token', value, { path, maxAge }),
	delete: () => removeItem('token')
};

export default tokenService;
