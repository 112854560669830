// Dependencies
import { makeAsyncAPIRequest, tokenHeader } from '../helpers/api';
import { apiVersion } from '../config';
const resource = 'admins';

/*
  Creates a User via the API

  @params   data    Object    The user data
*/
async function create(data) {
	const method = 'post';
	const url = `/${apiVersion}/${resource}`;
	return await makeAsyncAPIRequest({ method, url, data });
}

/*
  Updates the User via the API

  @params   data    Object    The user data
*/
async function update(id, data) {
	const headers = tokenHeader();
	const method = 'put';
	const url = `/${apiVersion}/${resource}/${id}`;
	return await makeAsyncAPIRequest({ method, url, data, headers });
}

/*
  Checks if a user exists, and if so, starts the password reset process for that
  user

  @params   data    Object    The user data
*/
async function forgotPassword(data) {
	const method = 'post';
	const url = `/${apiVersion}/${resource}/forgot-password`;
	return await makeAsyncAPIRequest({ method, url, data });
}

/*
  Checks if a forgot password token is valid, to validate the reset password
  request

  @params   token   String    The forgot password token
*/
async function checkForgotPasswordToken(token) {
	const method = 'get';
	const url = `/${apiVersion}/${resource}/forgot-password/${token}`;
	return await makeAsyncAPIRequest({ method, url });
}

/*
  Resets a user's password for them

  @params   fpToken   String    The forgot password token
  @params   data      Object    The reset password data
*/
async function resetPassword(fpToken, data) {
	const method = 'patch';
	const url = `/${apiVersion}/${resource}/forgot-password/${fpToken}`;
	return await makeAsyncAPIRequest({ method, url, data });
}

/*
  Closes the User account via the API

  @params   data    Object    The user data
*/
async function close(id, data) {
	const headers = tokenHeader();
	const method = 'put';
	const url = `/${apiVersion}/${resource}/${id}/close`;
	return await makeAsyncAPIRequest({ method, url, data, headers });
}

export default {
	create,
	update,
	checkForgotPasswordToken,
	forgotPassword,
	resetPassword,
	close
};
