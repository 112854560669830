// Dependencies
import axios from 'axios';
import tokenService from '../services/tokenService';

// Config
import { baseUrl } from '../config';

/*
  Makes an API request

  @params   options   Object  The HTTP request options
*/
async function makeAsyncAPIRequest(options) {
	options.url = baseUrl + options.url;
	return await axios(options);
}

const tokenHeader = token => {
	if (!token) token = tokenService.get();
	return { Authorization: `Bearer ${token}` };
};

export { tokenHeader, makeAsyncAPIRequest };
