import { secureCookie } from '../config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// Fetches from cookies
function getItem(key) {
	return cookies.get(key);
}

// Stores item via cookies
function setItem(key, value, cookieOptions) {
	cookieOptions.secure = secureCookie;
	return cookies.set(key, value, cookieOptions);
}

// Removes the item from cookies
function removeItem(key) {
	const sid = cookies.get(key);
	cookies.remove(key);
	return sid;
}

export { getItem, setItem, removeItem };
