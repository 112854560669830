// Dependencies
import { makeAsyncAPIRequest, tokenHeader } from '../helpers/api';
import { apiVersion } from '../config';
const resource = 'playlists';

// TODO - in order to use serviceTemplate, allow for defining namespace prefix options

const playlistsService = {
	get: async (id) => {
		const method = 'get';
		const url = `/${apiVersion}/admin/${resource}/${id}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({ method, url, headers });
		return res.data;
	},

	getAll: async () => {
		const method = 'get';
		const url = `/${apiVersion}/admin/${resource}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({ method, url, headers });
		return res.data;
	},

	create: async (data) => {
		const method = 'post';
		const url = `/${apiVersion}/admin/${resource}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({ method, url, headers, data });
		return res.data;
	},

	update: async (data) => {
		const method = 'put';
		const url = `/${apiVersion}/admin/${resource}/${data.id}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({
			method,
			url,
			data,
			headers,
		});
		return res.data;
	},

	delete: async (data) => {
		const method = 'delete';
		const url = `/${apiVersion}/admin/${resource}/${data.id}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({
			method,
			url,
			data,
			headers,
		});
		return res.data;
	},
};

export default playlistsService;
