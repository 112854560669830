// Dependencies
import { makeAsyncAPIRequest, tokenHeader } from '../helpers/api';
import { apiVersion } from '../config';
const resource = 'samples';
const samplesService = {
	get: async (id) => {
		const method = 'get';
		const url = `/${apiVersion}/admin/${resource}/${id}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({ method, url, headers });
		return res.data;
	},

	/*
    Gets the session from the API via the browser cookie
  */
	getAll: async () => {
		const method = 'get';
		const url = `/${apiVersion}/admin/${resource}`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({ method, url, headers });
		return res.data;
	},

	create: async (data) => {
		const method = 'post';
		const url = `/${apiVersion}/admin/${resource}`;
		const headers = tokenHeader();
		headers['content-type'] = 'multipart/form-data';
		return await makeAsyncAPIRequest({ method, url, headers, data });
	},

	search: async (data) => {
		const method = 'post';
		const url = `/${apiVersion}/admin/${resource}/search`;
		const headers = tokenHeader();
		const res = await makeAsyncAPIRequest({ method, url, headers, data });
		return res.data;
	},
};

export default samplesService;
